import React from "react";
import styles from "./InvisibleBtn.module.css";

export interface InvisibleBtnPops {
  right?: boolean;
  onClick: VoidFunction;
}

export default function InvisibleBtn({
  onClick,
  right = false,
}: InvisibleBtnPops) {
  return (
    <div
      className={[styles.container, right && styles.right].join(" ")}
      onClick={onClick}
    ></div>
  );
}
