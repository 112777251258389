import "../scripts/wdyr";
import React, { useEffect } from "react";
import "../styles/globals.css";
import { AuthProvider } from "../src/auth/AuthProvider";
import { AuthGuard } from "../src/auth/AuthGuard";
import SnackbarProvider from "react-simple-snackbar";
import * as gtag from "../lib/ga";
import { useRouter } from "next/router";
import * as Sentry from "@sentry/nextjs";
import NoInternetStatus from "../src/components/molecules/NoInternetStatus/NoInternetStatus";
import InvisibleBtn from "../src/components/atoms/InvisibleBtn/InvisibleBtn";
import { disableGa, enableGa } from "../src/util";
const isProduction = process.env.NODE_ENV === "production";

function MyApp(props) {
  const { Component, pageProps }: { Component: any; pageProps: any } = props;
  const router = useRouter();
  useEffect(() => {
    if (isProduction) initSentry();

    const handleRouteChange = (url: URL) => {
      /* invoke analytics function only for production */
      if (isProduction) gtag.pageview(url);
      else {
        console.log("non running in development");
      }
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <SnackbarProvider>
      <AuthProvider>
        {/* if requireAuth property is present - protect the page */}
        {Component.requireAuth ? (
          <AuthGuard LoaderComponent={Component.loaderComponent}>
            <Component {...pageProps} />
          </AuthGuard>
        ) : (
          // public page
          <Component {...pageProps} />
        )}
        <NoInternetStatus />

        <InvisibleBtn onClick={disableGa} />
        <InvisibleBtn onClick={enableGa} right />
      </AuthProvider>
    </SnackbarProvider>
  );
}

export default MyApp;

function initSentry() {
  console.log("init sentry");
  const SENTRY_DSN =
    process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

  const dns =
    SENTRY_DSN ||
    "https://2795389fe05f48288563ccc37d7acd0a@o947112.ingest.sentry.io/5896354";

  Sentry.init({
    dsn: dns,
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 1.0,
  });
}
