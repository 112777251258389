import React, { useEffect, useState } from "react";
import styles from "./NoInternetStatus.module.css";

export interface NoInternetStatusPops {}

export default function NoInternetStatus() {
  const [isOnline, setIsOnline] = useState(true);
  const setOnline = () => setIsOnline(navigator.onLine);
  useEffect(() => {
    window.addEventListener("online", setOnline);
    window.addEventListener("offline", setOnline);
    return () => {
      window.removeEventListener("online", setOnline);
      window.removeEventListener("offline", setOnline);
    };
  }, []);
  return (
    <div className={[styles.container, isOnline && styles.close].join(" ")}>
      {isOnline ? "" : "You are offline. Please connect to the internet"}
    </div>
  );
}
