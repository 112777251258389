import React from 'react';

if (false) {
  if (true) {
    var whyDidYouRender = require('@welldone-software/why-did-you-render');

    whyDidYouRender(React, {
      trackAllPureComponents: true // include: [/[A-za-z]/]

    });
  }
}